.footer {
    width: 100%;
    padding: var(--page-padding);

    position: relative;

    &__column {
        background: var(--color-accent);
        border-radius: var(--border-radius);

        padding: var(--page-padding);
        padding-bottom: calc(var(--page-padding) * 2);
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: end;
    }

    &__nav {
        display: flex;
        align-items: center;

        width: min-content;

        background: var(--color-light);
        border-radius: var(--border-radius);

        padding: var(--page-padding);

        gap: 0.75rem;

        .chip {
            &:hover {
                background: var(--color-darkest);
                color: var(--color-accent);

                border-color: var(--color-darkest);
            }
        }
    }

    &__title {
        margin: 0;
        margin-top: calc(var(--page-padding));

        font-size: clamp(4.5rem, 7vw, 5.75rem);
        font-weight: 500;

        letter-spacing: -0.25rem;
        line-height: 0.8;

        text-wrap: nowrap;

        span {
            font-weight: 400;
            visibility: hidden;
        }
    }

    &__picker {
        width: 16px;
        height: 16px;
        border-radius: var(--border-radius);
        outline: 3px solid var(--color-light);

        animation: rainbow var(--animation-duration) ease-in-out infinite;
        --animation-duration: 10s;
        position: relative;

        cursor: pointer;

        transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);

        &:hover:not(.active) {
            scale: 0.9;
        }

        span {
            position: absolute;
            z-index: 100;

            bottom: 50%;
            right: 0;
            transform: translate(0, 50%);

            background: rgba(255, 255, 255, 0.75);
            backdrop-filter: blur(10px) saturate(280%);

            border-radius: inherit;
            padding: var(--page-padding);

            overflow: hidden;

            width: 0;
            height: 0;
            opacity: 0;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        background: linear-gradient(
            124deg,
            #ff2400,
            #e81d1d,
            #e8b71d,
            #e3e81d,
            #1de840,
            #1ddde8,
            #2b1de8,
            #dd00f3,
            #dd00f3
        );
        background-size: 1500% 1500%;

        @keyframes rainbow {
            0% {
                background-position: 0% 82%;
            }
            50% {
                background-position: 100% 19%;
            }
            100% {
                background-position: 0% 82%;
            }
        }
    }

    .active {
        --animation-duration: 9s;
    }

    &__wave {
        position: absolute;

        right: 30px;
        bottom: 30px;

        animation: wiggle 2s ease-in-out infinite;

        color: var(--color-darkest);

        @keyframes wiggle {
            0% {
                transform: rotate(-15deg);
            }
            50% {
                transform: rotate(1deg);
            }
            100% {
                transform: rotate(-15deg);
            }
        }
    }
}

@media screen and (max-width: 930px) {
    .footer {
        &__text {
            display: none;
        }
    }
}

@media screen and (max-width: 672px) {
    .footer {
        &__row:nth-of-type(2) {
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
        }

        &__wave {
            display: none;
        }

        &__picker {
            margin-top: 0.5rem;
        }
    }
}

@media screen and (max-width: 538px) {
    .footer {
        &__title {
            margin-top: var(--page-padding);
            text-wrap: wrap;
        }

        &__nav {
            min-width: 100%;
            overflow-x: scroll;
        }
    }
}
