// Not actually P3 color spectrum but close enough
// Please change later you lazy bum

:root {
    --color-light: oklch(97.94% 0.0148 222);
    --color-accent: oklch(91.61% 0.0443 256);
    --color-accent-2: oklch(91.76% 0.0444 245.65);
    --color-dark: oklch(77.08% 0.0235 58);
    --color-darker: oklch(39.47% 0.0309 293 / 20%);
    --color-darkest: oklch(16.47% 0.0611 254);

    --page-padding: 0.65rem;
    --border-radius: 0.5rem;
}

@supports not (color: oklch(0% 0 0)) {
    :root {
        --color-light: hsl(192.09 100% 97%);
        --color-accent: hsl(208.9 100% 92%);
        --color-accent-2: hsl(185.49 100% 97%);
        --color-dark: hsl(25.03 17% 70%);
        --color-darker: hsla(251, 12%, 30%, 20%);
        --color-darkest: hsl(213.82 57% 6%);
    }
}

body {
    color: var(--color-darkest);
    background-color: var(--color-darkest);
    font-family: Akzidenz, sans-serif;
    overflow-x: hidden;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ::-moz-selection {
        /* Code for Firefox */
        color: var(--color-light);
        background: var(--color-darkest);
    }

    ::selection {
        color: var(--color-light);
        background: var(--color-darkest);
    }
}

a {
    text-decoration: none;
    all: unset;
    cursor: pointer;
    color: var(--color-darkest) !important;
}

html.lenis,
html.lenis body {
    height: auto;
}

.lenis.lenis-smooth {
    scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-stopped {
    overflow: hidden;
}

.lenis.lenis-scrolling iframe {
    pointer-events: none;
}

@font-face {
    font-family: "Akzidenz";
    src: url("../../fonts/AKZIDENZGROTESKPRO-REGULAR.TTF");
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: "Akzidenz";
    src: url("../../fonts/AKZIDENZGROTESKPRO-MEDIUM.TTF");
    font-weight: 500;
    font-display: swap;
}
